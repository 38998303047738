<template>
  <div>
    <td style="width: 10rem;display: flex;justify-content: space-around;">
      <div v-if="this.item.approved_status > -1">
        <a :href="`${item.doc_url}`" target="_blank">
          <i class="fas fa-eye"></i>
        </a>
      </div>
      <div
        v-if="this.item.approved_status === 2  || this.item.approved_status === -1 || this.item.approved_status === 0"
      >
        <i @click="handleUpdate(1)" class="fas fa-check text-info"></i>
      </div>
      <div
        v-if="this.item.approved_status > -1 && this.item.approved_status === 1"
      >
        <i @click="handleUpdate(0)" class="fas fa-times text-danger"></i>
      </div>
    </td>

    <modal :show.sync="modal" headerClasses="justify-content-center" centered>
      <h4 slot="header" class="title title-up">
        Reprovar documento
      </h4>

      <div class="col-12 p-0 mb-3">
        <div class="row">
          <div class="col-12">
            <label for="">Observações</label>
            <textarea class="form-control" v-model="observation"></textarea>
          </div>
        </div>
      </div>

      <template slot="footer">
        <base-button :disabled="loading" type="danger" @click.native="cancel">
          Cancelar
        </base-button>
        <base-button
          :disabled="loading"
          type="primary"
          @click.native="updateDoc(approved, observation)"
        >
          {{ loading ? 'Salvando...' : 'Salvar' }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import { Modal } from '@/components/index'

export default {
  props: {
    item: Object,
    beforeUpdate: Function
  },

  data () {
    return {
      modal: false,
      loading: false,
      approved: 0,
      observation: ''
    }
  },

  methods: {
    cancel () {
      this.modal = false
      this.loading = false
      this.approved = 0
      this.observation = ''
    },
    async updateDoc (approved, observation) {
      this.loading = true
      const url = `admin/user-docs/${this.item.doc_id}`
      const data = { approved, observation: observation || '' }

      const response = await this.$http.put(url, data)

      if (response.status === 200) {
        this.beforeUpdate()

        if (observation) {
          this.modal = false
          swal({
            title: 'Alterado!',
            text: `Você alterou "${this.item.document}" com sucesso.`,
            type: 'success',
            showConfirmButton: false,
            timer: 2000
          })
        }

        return true
      } else {
        if (observation) {
          swal({
            title: 'Error!',
            text: `Ocorreu um erro ao alterar "${this.item.document}".`,
            type: 'error',
            confirmButtonClass: 'btn btn-danger btn-fill',
            confirmButtonText: 'Ok',
            showConfirmButton: true
          })
        }
        return false
      }
    },
    handleUpdate (status) {
      if (status === 0) {
        this.modal = true
        this.observation = this.item.observation
        this.loading = false
        return
      }

      swal
        .fire({
          title: 'Você tem certeza?',
          text: `Deseja aprovar este documento? : "${this.item.document}".`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Sim, eu quero aprovar!',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        })
        .then(result => {
          if (result.value) {
            swal.fire({
              title: 'Alterando',
              text: `Aguarde, estamos alterando "${this.item.document}".`,
              type: 'info',
              showConfirmButton: false,
              onBeforeOpen: () => {
                swal.showLoading()
              }
            })
            this.updateDoc(status, '').then(status => {
              if (status) {
                swal.fire({
                  title: 'Alterado!',
                  text: `Você aprovou "${this.item.document}" com sucesso.`,
                  type: 'success',
                  showConfirmButton: false,
                  timer: 2000
                })
              } else {
                swal.fire({
                  title: 'Error!',
                  text: `Ocorreu um erro ao alterar "${this.item.document}".`,
                  type: 'error',
                  confirmButtonClass: 'btn btn-danger btn-fill',
                  confirmButtonText: 'Ok',
                  showConfirmButton: true
                })
              }
            })
          }
        })
    }
  },
  components: {
    Modal
  }
}
</script>
